import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {TCategory} from "@/types/TCategory";
import {TOptionCategory} from "@/types/TOptionCategory";
import {TDimensionsOrder} from "@/types/TDimensionsOrder";
import {TUm1Um2} from "@/types/TUm1Um2";


interface TAjaxResponseSimple {
    status: string;
    message: string;
}

type TAjaxResponseGetCategory= {
    status: string;
    message: string;
    category:TCategory&TUm1Um2&{parent_name_ro:string,parent_name_en:string,parent_name_hu:string}&{hasChilds:string};
}

interface TAjaxResponseGetNomCategory {
    status: string;
    message: string;
    treeDataCategories:TCategory[];
}

interface TAjaxResponseGetNomCategoryList {
    status: string;
    message: string;
    listDataCategories:TOptionCategory[];
}

export class ServiceAdminNomCategory {
    public static async getNomCategoryTreeData(): Promise<TAjaxResponseGetNomCategory> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.treeDataCategories}/${rnd}`);
        return response.data;
    }

    public static async getNomCategorylistData(): Promise<TAjaxResponseGetNomCategoryList> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.listDataCategories}/${rnd}`);
        return response.data;
    }

    public static async postCategory(pcategory: TCategory&TDimensionsOrder&TUm1Um2&{img:any|null}): Promise<TAjaxResponseSimple> {
        const param = new FormData(); //Create a form object
        param.append('file', pcategory.img);//Add data to the form object via append
        param.append('pid', pcategory.pid);
        param.append('parent_pid', pcategory.parent_pid);
        param.append('name_ro', pcategory.name_ro);
        param.append('name_en', pcategory.name_en);
        param.append('name_hu', pcategory.name_hu);
        param.append('with_length', pcategory.withLength);
        param.append('with_width', pcategory.withWidth);
        param.append('with_thickness', pcategory.withThickness);
        param.append('with_diameter', pcategory.withDiameter);
        param.append('with_height', pcategory.withHeight);
        param.append('with_alloy', pcategory.withAlloy);
        param.append('with_eyes', pcategory.withEyes);
        param.append('with_eyes2', pcategory.withEyes2);
        param.append('length_position', pcategory.positionLength.toString());
        param.append('width_position', pcategory.positionWidth.toString());
        param.append('diameter_position', pcategory.positionDiameter.toString());
        param.append('thickness_position', pcategory.positionThickness.toString());
        param.append('height_position', pcategory.positionHeight.toString());
        param.append('alloy_position', pcategory.positionAlloy.toString());
        param.append('eyes_position', pcategory.positionEyes.toString());
        param.append('eyes_position2', pcategory.positionEyes2.toString());
        param.append('um1', pcategory.um1);
        param.append('um2', pcategory.um2);
        param.append('is_parent_for_articles', pcategory.is_parent_for_articles);

        const config = {
            headers: {'Content-Type': 'multipart/form-data'}
        };
        //const params = new URLSearchParams();
        //params.set('name', pcategory.name);
        //params.set('img', pcategory.img);
        let response;
        if(pcategory.pid){
            response = await axios.post(`${CONFIG_ENV.URL_ADMIN.nomCategory}/${pcategory.pid}`, param, config);
        }else{
            response = await axios.post(`${CONFIG_ENV.URL_ADMIN.nomCategory}`, param, config);
        }

        return response.data;
    }

    public static async deleteCategory(pid:string): Promise<TAjaxResponseSimple> {
        const response = await axios.delete(`${CONFIG_ENV.URL_ADMIN.nomCategory}/${pid}`);
        return response.data;
    }

    public static async getCategory(pid:string): Promise<TAjaxResponseGetCategory> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.nomCategory}/${pid}`);
        return response.data;
    }

    public static async getImageCategoryAsString(pid:string): Promise<string> {
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.imageCategoryAsString}/${pid}`);
        return response.data;
    }

    public static async getProfileImgAsString(puserappid:string) {
        const res = await  <Promise<string>>  axios.get(`${CONFIG_ENV.URL_USER.getMyImageProfileAsString}/${puserappid}`);
        return res;
    }

    public static async changeOrder(category: TCategory): Promise<TAjaxResponseSimple> {
        const params = new FormData();
        params.append('pid_category', category.pid);
        params.append('ord', category.ord.toString());
        const config = {
            headers: {'Content-Type': 'multipart/form-data'}
        };
        const response = await axios.post(`${CONFIG_ENV.URL_ADMIN.changeOrderCategory}`, params, config);
        return response.data;
    }
}
