import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import { TAlerta } from '@/types/TAlerta';


type TAjaxResponseGetAlerts={
    status: string;
    message: string;
    alerts: TAlerta[];
}

type TAjaxResponseGetCounters={
    status: string;
    message: string;
    countAdrese: number;
    countOferte: number;
}

interface TAjaxResponseSimple {
    status: string;
    message: string;
}

export class ServiceAlerts {

    public static async getAlertsFromDB(): Promise<TAjaxResponseGetAlerts> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_ALERTS.getMyAlerts}`);
        return response.data;
    }

    public static async getCounters(): Promise<TAjaxResponseGetCounters> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_COUNTER.getCounters}`);
        return response.data;
    }

    
    public static async sendNotification(subject: string): Promise<TAjaxResponseSimple>{
        const params = new URLSearchParams();
        params.set('subject', subject);
        const response = await axios.post(`${CONFIG_ENV.URL_ALERTS.sendNotidication}`, params);
        return response.data;
    }

    public static async sendNotificationEmail(subject: string): Promise<TAjaxResponseSimple>{
        const params = new URLSearchParams();
        params.set('subject', subject);
        const response = await axios.post(`${CONFIG_ENV.URL_ALERTS.sendNotidicationEmail}`, params);
        return response.data;
    }

    public static async sendNotificationEmailBK(subject: string){
        const params = new URLSearchParams();
        params.set('subject', subject);
        const response = await axios.post(`${CONFIG_ENV.URL_ALERTS.sendNotidicationEmailBK}`, params);
        // return response.data;
    }

}
